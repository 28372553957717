import React from "react";
import Container from "react-bootstrap/Container";

const Footer = (props) => {
  const bgStyle = { backgroundColor: "#C0D1B8" };

  return (
    <footer style={bgStyle} className="mt-auto py-3 text-center ">
      <Container>
        {props.children}
        with <i className="fas fa-heart" /> by{" "}
        <a
          className="badge badge-primary p-1"
          rel="noopener"
          href="https://github.com/jibisk"
          aria-label="My GitHub"
        >
          Jibi Subhash Kesavan
        </a>
        
      </Container>
    </footer>
  );
};

export default Footer;
