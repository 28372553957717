// Navigation Bar SECTION
const navBar = {
  show: false,
};

// Main Body SECTION
const mainBody = {
  gradientColors: "#010221, #0A7373, #B7BF99, #EDAA25, #C43302, #334D5C",
  firstName: "Jibi",
  middleName: "",
  lastName: "Subhash Kesavan",
  message: "Front End Web Developer | UI Designer | HTML | React | Javascript | Typescript | ES6 | CSS | SCSS | Angular",
  icons: [
    {
      image: "fa-github",
      url: "https://github.com/jibisk",
    },
    // {
    //   image: "fa-facebook",
    //   url: "https://www.facebook.com/jibisk",
    // },
    // {
    //   image: "fa-instagram",
    //   url: "https://www.instagram.com/jibisk/",
    // },
    {
      image: "fa-linkedin",
      url: "https://ae.linkedin.com/in/jibisk",
    },
    {
      image: "fa-twitter",
      url: "https://twitter.com/jibisk",
    },
    {
      image: "fa-goodreads",
      url: "https://www.goodreads.com/jibisk",
    },
  ],
};

// ABOUT SECTION
// If you want the About Section to show a profile picture you can fill the profilePictureLink either with:
//a) your Instagram username
//      i.e:profilePictureLink:"johnDoe123",
//b) a link to an hosted image
//      i.e:profilePictureLink:"www.picturesonline.com/johnDoeFancyAvatar.jpg",
//c) image in "editable-stuff" directory and use require("") to import here,
//      i.e: profilePictureLink: require("../editable-stuff/jibisk.png"),
//d) If you do not want any picture to be displayed, just leave it empty :)
//      i.e: profilePictureLink: "",
// For Resume either provide link to your resume or import from "editable-stuff" directory
//     i.e resume: require("../editable-stuff/resume.pdf"),
//         resume: "https://docs.google.com/document/d/13_PWdhThMr6roxb-UFiJj4YAFOj8e_bv3Vx9UHQdyBQ/edit?usp=sharing",

const about = {
  show: true,
  heading: "About Me",
  imageLink: require("../editable-stuff/jibisk.png"),
  imageSize: 375,
  message:
    "Myself Jibi Subhash Kesavan, my goal is to obtain a challenging position that provides me opportunities to exploit my knowledge and extensive development skills to the extreme levels of my ability and to continually upgrade myself to the highest levels of expertise enabling the organization to achieve its target and growth.",
  resume: "https://docs.google.com/document/d/1UJABfwo57CjTScBlwOsPQqHyxFPsjb-4/edit?usp=sharing&ouid=116971099599497342975&rtpof=true&sd=true",
};

// PROJECTS SECTION
// Setting up project lenght will automatically fetch your that number of recently updated projects, or you can set this field 0 to show none.
//      i.e: reposLength: 0,
// If you want to display specfic projects, add the repository names,
//      i.e ["repository-1", "repo-2"]
const repos = {
  show: true,
  heading: "My GitHub",
  gitHubUsername: "jibisk", //i.e."johnDoe12Gh"
  reposLength: 4,
  specificRepos: [],
};

// Leadership SECTION
// const leadership = {
//   show: false,
//   heading: "Leadership",
//   message:
//     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vitae auctor eu augue ut lectus arcu bibendum at varius. Libero justo laoreet sit amet cursus sit amet. Imperdiet dui accumsan sit amet nulla facilisi morbi. At auctor urna nunc id. Iaculis urna id volutpat lacus laoreet non curabitur gravida. Et magnis dis parturient montes nascetur ridiculus mus mauris. In nisl nisi scelerisque eu ultrices vitae auctor. Mattis nunc sed blandit libero volutpat sed cras ornare. Pulvinar neque laoreet suspendisse interdum consectetur libero.",
//   images: [
//     { 
//       img: require("../editable-stuff/jibisk.png"), 
//       label: "First slide label", 
//       paragraph: "Nulla vitae elit libero, a pharetra augue mollis interdum." 
//     },
//     { 
//       img: require("../editable-stuff/jibisk.png"), 
//       label: "Second slide label", 
//       paragraph: "Nulla vitae elit libero, a pharetra augue mollis interdum." 
//     },
//   ],
//   imageSize: {
//     width:"615",
//     height:"450"
//   }
// };

// SKILLS SECTION
const skills = {
  show: true,
  heading: "Skills",
  hardSkills: [
    { name: "HTML5 / XHTML", value: 90 },
    { name: "CSS3 / SASS / LESS", value: 90 },
    { name: "JavaScript / JQuery", value: 80 },
    { name: "Photoshop / Illustrator", value: 90 },
    { name: "VS Code / Bracket", value: 50 },
    { name: "TypeScript / ES6", value: 75 },
    { name: "Npm / Yarn", value: 50 },
    { name: "Node / Express.js", value: 20 },
    { name: "React / Next.js / Redux", value: 75 },
    { name: "Bootstrap UI", value: 80 },
    { name: "Angular", value: 40 },
    { name: "Git/ GitHub", value: 50 },
    { name: "Responsive mailer", value: 70 },
    { name: "UI Designs", value: 80 },
    { name: "Firebase", value: 20 },
    { name: "Mongo DB", value: 10 },
  ],
  softSkills: [
    { name: "Goal-Oriented", value: 80 },
    { name: "Collaboration", value: 90 },
    { name: "Positivity", value: 75 },
    { name: "Adaptability", value: 85 },
    { name: "Problem Solving", value: 75 },
    { name: "Empathy", value: 90 },
    { name: "Organization", value: 70 },
    { name: "Creativity", value: 90 },
  ],
};

// GET IN TOUCH SECTION
const getInTouch = {
  show: true,
  heading: "Reach me",
  message:
    "I'm currently looking for full-time/on-site Front End Development opportunities! If you know of any positions available, please feel free to reach me ",
  email: "jiby.elavinmootil@gmail.com",
};

const experiences = {
  show: false,
  heading: "Experience",
  data: [
    {
      role: 'Front end Developer / UI Designer',
      companylogo: require('../assets/img/TechKnowledge.png'),
      date: 'April 2013 – Present',
    },
    {
      role: 'UI Designer',
      companylogo: require('../assets/img/IBS_Software.svg'),
      date: 'June 2010 – November 2012',
    },
    {
      role: 'UI / Web / Graphic Designer',
      companylogo: require('../assets/img/Integrated_Dynamic_Solutions.jpg'),
      date: 'November 2007 – June 2010 ',
    },
    {
      role: 'UI Designer',
      companylogo: require('../assets/img/Vanilla-Networks.jpg'),
      date: 'February 2006 – November 2007',
    },
  ]
}

// Blog SECTION
// const blog = {
//   show: false,
// };

export { navBar, mainBody, about, skills, experiences, repos, getInTouch };
//export { navBar, mainBody, about, repos, skills, leadership, getInTouch, experiences };
